import Vue from 'vue'
import Index from './views/index/App.vue'
import Login from './views/login/App.vue'
import Search from './components/search.vue'
import Table from "./components/old/table/query.vue"
import router from './router'
import store from './store'

import "./views/index/events"
import "./assets/css/iconfont.css"
import './plugins/element'
import './axios'

Vue.config.productionTip = false

Vue.component('Search', Search);
Vue.component('Table', Table);

location.pathname.includes("/login") && sessionStorage.removeItem("SHZS-LOGIN-TOKEN");

const Page = sessionStorage.getItem("SHZS-LOGIN-TOKEN") ? Index : Login;

new Vue({
    router,
    store,
    render: h => h(Page)
}).$mount('#app')
