export default {
    "common-input": resolve => require.ensure([], () => resolve(require("./input.vue"))),
    "common-range": resolve => require.ensure([], () => resolve(require("./range.vue"))),
    "common-select": resolve => require.ensure([], () => resolve(require("./select.vue"))),
    "common-radio": resolve => require.ensure([], () => resolve(require("./radio.vue"))),
    "common-date": resolve => require.ensure([], () => resolve(require("./date.vue"))),
    "common-time": resolve => require.ensure([], () => resolve(require("./time.vue"))),
    "common-checkbox": resolve => require.ensure([], () => resolve(require("./checkbox.vue"))),
    "common-upload": resolve => require.ensure([], () => resolve(require("./upload.vue"))),
    "common-cascader": resolve => require.ensure([], () => resolve(require("./cascader.vue"))),
}