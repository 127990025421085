<template>
    <div :style="{ '--max-height': height + 'px' }">
        <div
            ref="search"
            :class="[
                'page-search-panel',
                { expand: expand },
                { showExpand: showExpand }
            ]"
            @keypress.enter="search"
        >
            <!-- <a class="expand-btn" v-if="showExpand" @click="epd">
            {{ expandText }}
        </a> -->
            <el-button class="expand-btn" v-if="showExpand" @click="epd">{{
                expandText
            }}</el-button>
            <div class="search-item" v-for="item in items" :key="item.key">
                <p class="search-label" v-if="item.label">{{ item.label }}</p>
                <component
                    :is="'common-' + (item.type || 'input')"
                    :item="item"
                    :data.sync="data"
                ></component>
            </div>
            <div
                class="search-item"
                style="margin-right: 0; min-width: 0"
                v-if="small"
            >
                <p class="search-btn search" @click="search">搜索</p>
                <p class="search-btn reset" @click="reset">重置</p>
            </div>
        </div>
        <div class="search-item btn-content" v-if="!small">
            <p class="search-btn search" @click="search">搜索</p>
            <p class="search-btn reset" @click="reset">重置</p>
        </div>
    </div>
</template>

<script>
import component from "./common/component";
import { RESET_SEARCH } from "../views/index/events/eventBus";
export default {
    data() {
        return {
            expand: false,
            showExpand: true,
            height: 0
        };
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        data: {
            type: Object,
            default: () => {
                return {};
            }
        },
        small: {
            type: Boolean,
            default: () => false
        }
    },
    watch: {
        data: {
            deep: true,
            handler() {
                Object.keys(this.data).forEach(key => {
                    if (!this.data[key]) {
                        this.$delete(this.data, key);
                    }
                });
            }
        }
    },
    computed: {
        expandText() {
            return this.expand ? "收起" : "展开";
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.height = this.$refs.search.scrollHeight;
            setTimeout(() => {
                this.isShowExpand();
            }, 100);
        });
        let _ = this;
        window.onresize = function () {
            _.isShowExpand();
        };
    },
    methods: {
        isShowExpand() {
            let PP = this.$refs.search;
            let SH = PP.scrollHeight;
            let H = PP.offsetHeight;
            this.showExpand = SH > H + 10;
        },
        epd() {
            this.height = this.$refs.search.scrollHeight;
            this.expand = !this.expand;
        },
        search() {
            this.$emit("search");
        },
        reset() {
            // this.$emit("reset");
            // this.$emit("update:data", {});
            this.$EventBus.$emit(RESET_SEARCH);
        }
    },
    components: { ...component }
};
</script>

<style lang="scss">
.page-search-panel {
    position: relative;
    background: #fff;
    max-height: 92px;
    min-height: 92px;
    border-bottom: 1px solid #ededed;
    flex: none;
    transition: all 0.2s linear 0s;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 10px 30px;
    box-sizing: border-box;
    &.showExpand {
        padding-right: 60px;
    }
    &.expand {
        max-height: var(--max-height);
    }
    .expand-btn {
        position: absolute;
        top: 28px;
        right: 20px;
        border: none;
        padding: 11px 20px;
        cursor: pointer;
        &:focus {
            color: #606266;
            background: #fff;
        }
        &:hover {
            color: #409eff;
            background-color: #ecf5ff;
        }
    }
    .search-item {
        display: flex;
        align-content: center;
        margin-right: 45px;
        padding: 18px 0;
        min-width: 220px;
        max-width: 450px;
        &.all-line {
            width: 100%;
            padding-right: calc(100% - 420px);
        }
        &.btn-content {
            margin-right: 0;
        }
        .search-label {
            flex: none;
            line-height: 36px;
            margin-right: 15px;
            color: rgb(62, 62, 62);
            font-size: 14px;
        }
        .el-input__inner,
        .search-btn {
            height: 36px;
            line-height: 36px;
        }
        .el-input__prefix {
            height: 36px;
            .el-icon-search {
                line-height: 36px;
            }
        }
        .el-radio-group {
            display: flex;
            align-items: center;
        }
        .el-switch {
            height: 36px;
            line-height: 36px;
        }
    }
}

.btn-content {
    background: #fff;
    display: flex;
    padding: 10px 30px;
    justify-content: center;
    // box-shadow: 0 3px 3px #efefef;
}
.search-btn {
    width: 54px;
    border-radius: 3px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    height: 36px;
    line-height: 36px;

    margin: 0 22px;
    &.search {
        background: rgb(86, 150, 247);
        color: #fff;
    }
    &.reset {
        background: rgb(240, 242, 245);
        border: 1px solid rgb(215, 219, 224);
    }
}
</style>

