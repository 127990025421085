<template comments>
    <div class="table-info-panel">
        <el-table
            ref="table"
            :data="tableData"
            :size="size"
            :max-height="maxHeight"
            :height="maxHeight"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            :row-key="rowKey"
            :highlight-current-row="highlight"
            :border="border"
            :show-summary="showSummary"
            :summary-method="summaryMethod"
            :header-cell-style="{ 'text-align': headerAlign }"
            v-loading="loading"
            :class="['query-table', { 'has-footer': showSummary }]"
            :default-expand-all="expandAll"
            @row-click="rowClick"
            @current-change="currentChange"
            @selection-change="selectionChange"
            @select="select"
            @sort-change="sortChange"
            @expand-change="expandChange"
        >
            <el-table-column v-if="expand" type="expand" label="展开" width="0">
                <template slot-scope="scope">
                    <slot name="expand" :row="scope.row"></slot>
                </template>
            </el-table-column>
            <el-table-column
                v-if="selectBox"
                fixed
                type="selection"
                width="50"
            ></el-table-column>
            <el-table-column
                v-if="sno"
                type="index"
                :label="snoName"
                width="50"
            ></el-table-column>
            <query-column
                v-for="(column, index) in tableColumns"
                :key="index"
                :column="column"
                :tableMap="tableMap"
            />
            <el-table-column v-if="moreOpt" label="操作" :width="120">
                <div
                    style="display: flex; justify-content: center"
                    slot-scope="scope"
                >
                    <i
                        v-for="opt in tableOperate.data[
                            scope.row[tableOperate.key]
                        ]"
                        :key="opt.icon"
                        :class="['iconfont table-operate-btn', opt.icon]"
                        :style="{ backgroundColor: opt.back }"
                        :title="opt.title || ''"
                        @click.stop="
                            opt.handle(JSON.parse(JSON.stringify(scope.row)))
                        "
                    ></i>
                </div>
            </el-table-column>
            <template v-else>
                <el-table-column
                    v-if="tableOperate.length > 0"
                    label="操作"
                    :width="tableOperate.length * 50"
                >
                    <template slot-scope="scope">
                        <i
                            v-for="opt in tableOperate"
                            :key="opt.icon"
                            :class="['iconfont table-operate-btn', opt.icon]"
                            :style="{ backgroundColor: opt.back }"
                            :title="opt.title || ''"
                            @click.stop="
                                opt.handle(
                                    JSON.parse(JSON.stringify(scope.row))
                                )
                            "
                        ></i>
                    </template>
                </el-table-column>
            </template>
        </el-table>
        <div class="pagination" v-if="!nopage">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 50]"
                :page-size.sync="pageSize"
                layout="sizes, prev, pager, next, jumper"
                :total="total"
                :hide-on-single-page="true"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
import QueryColumn from "./queryColumn.vue";
export default {
    data() {
        return {
            comments: true,
            isLoad: false,
            expandRow: null,
            selections: null,
            current: null,
            maxHeight: this.nopage ? "100%" : "calc(100% - 48px)"
        };
    },
    props: {
        // maxHeight: { type: String, default: "calc(100% - 48px)" }, //表格主体高度设置，无特殊情况无需设置
        size: { type: String, default: "mini" }, //表格尺寸，无特殊情况无需设置
        tableData: { type: Array, default: null }, //表格数据
        selectBox: { type: Boolean, default: false }, //是否展示选择框
        sno: { type: Boolean, default: false }, //是否生成序号
        snoName: { type: String, default: "序号" }, //序号列名，默认值为 “序号”
        tableColumns: { type: Array, default: null }, //需要展示的列，对象数组，包含label(列名), prop(key)
        loading: { type: Boolean, default: false }, //是否展示加载动画
        tableMap: { type: Object, default: null }, //展示内容映射，如存储的数字需要展示为相应含义的中文
        noMore: { type: Boolean, default: false }, //是否有更多数据，用于判断是否需要继续加载下一页
        rowKey: { type: String, default: "" }, //列的唯一标识符，无特殊处理无需设置
        highlight: { type: Boolean, default: false }, //是否高亮当前点击行
        // currentChange: { type: Function, default: () => {} }, //配合highlight使用，返回当前点击行，可用于行编辑
        border: { type: Boolean, default: true }, //是否展示表格边框，false展示为无边框表格
        showSummary: { type: Boolean, default: false }, //是否展示合计栏
        summaryColumns: { type: Array, default: null }, //合计栏带合计的列，为string类型的数组
        sortChange: { type: Function, default: () => {} }, //远程排序方法，由父组件传入
        headerAlign: { type: String, default: "center" }, //表头列名的文字对齐方式，默认左对齐，可设置center、right
        total: { type: Number, default: () => 0 },
        pageSize: { type: Number, default: () => 10 },
        currentPage: { type: Number, default: () => 1 },
        tableOperate: { type: Array | Object, default: () => null },
        expand: { type: Boolean, default: () => false },
        nopage: { type: Boolean, default: () => false },
        expandAll: { type: Boolean, default: () => false },
        moreOpt: { type: Boolean, default: () => false }
    },
    methods: {
        expandChange(row, expanded) {
            console.log(expanded);
            if (expanded.length > 1) {
                this.$refs.table.toggleRowExpansion(expanded[0], false);
            }
            if (row === expanded[expanded.length - 1]) {
                this.$emit("expand-row", row);
            }
        },
        handleSizeChange(size) {
            this.$emit("update:pageSize", size);
        },
        handleCurrentChange(current) {
            this.$emit("update:currentPage", current);
        },
        rowClick: function (row) {
            this.$refs.table.clearSelection();
            this.$refs.table.toggleRowSelection(row, true);
        },
        selectionChange: function (selection) {
            this.selections = selection;
            this.$emit("selected-item", selection);
        },
        currentChange(current) {
            this.current = current;
        },
        select: function (selection, row) {
            if (selection.indexOf(row) > -1) {
                this.$refs.table.setCurrentRow(row);
            } else {
                this.$refs.table.setCurrentRow();
            }
        },
        load: function () {
            if (this.noMore) return;
            this.$emit("load");
        },
        clearCurrent: function () {
            this.$refs.table.setCurrentRow();
            this.$refs.table.clearSelection();
        },
        summaryMethod: function (param) {
            const { columns, data } = param;
            let sums = [],
                values = [];
            columns.forEach((column, index) => {
                if (index == 0) {
                    sums[index] = "统计";
                    return;
                }
                if (this.summaryColumns.indexOf(column.property) > -1) {
                    let values = data.map(item =>
                        Number(item[column.property])
                    );
                    sums[index] = values.reduce((prev, curr) => prev + curr, 0);
                } else {
                    sums[index] = "--";
                }
            });
            return sums;
        }
    },
    watch: {
        total() {
            this.maxHeight =
                this.pageSize >= this.total
                    ? "calc(100% - 1px)"
                    : "calc(100% - 48px)";
        }
    },
    components: { QueryColumn }
};
</script>

<style lang="scss">
.table-info-panel {
    flex-grow: 1;
    flex: 1;
    height: 0;
    // display: flex;
    // flex-direction: column;
    width: 100%;
    // overflow: hidden;
    .query-table.el-table {
        height: calc(100% - 100px);
        width: 100%;
        display: block;
        // .el-table__expand-column {
        //     opacity: 0;
        // }
        // .el-table__expanded-cell {
        //     padding: 0;
        // }
        .table-opt-btn {
            padding: 0;
        }
        &.el-table--border {
            // border: none;
            border-top: 1px solid #ebeef5;
        }
    }
    .table-operate-btn {
        display: inline-block;
        font-size: 12px;
        color: #fff;
        padding: 1px 6px;
        cursor: pointer;
        background-color: rgb(45, 140, 240);
        border-radius: 4px;
        & + .table-operate-btn {
            margin-left: 5px;
        }
    }
    .loading-slot {
        height: 40px;
        line-height: 40px;
        text-align: center;
    }
    .pagination {
        background: #fff;
        // position: absolute;
        // bottom: 0;
        // width: 100%;
        .el-pagination {
            padding: 10px;
        }
    }
}
// .el-dialog .table-info-panel .query-table.el-table {
//     position: unset;
// }
</style>
