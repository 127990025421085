import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { PARENT_MUTATION, SHOW_404_MUTATION } from "../store/mutationBus"

const BASE_URL = process.env.BASE_URL

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        redirect: "/product"
    },
    {
        path: "/product",
        name: "商品管理",
        component: resolve => require.ensure([], () => resolve(require("../views/index/pages/product/index.vue"))),
        meta: {}
    },
    {
        path: "/login",
        name: "登录",
        component: resolve => require.ensure([], () => resolve(require("../views/login/App.vue"))),
        meta: {}
    },
    {
        path: "/bill",
        name: "订单管理",
        component: resolve => require.ensure([], () => resolve(require("../views/index/pages/bill/index.vue"))),
        meta: {
            parent: "bill"
        }
    },
    {
        path: "/msg",
        name: "评论管理",
        component: resolve => require.ensure([], () => resolve(require("../views/index/pages/msg/index.vue"))),
        meta: {
            parent: "bill"
        }
    },
    {
        path: "/aftermarket",
        name: "售后管理",
        component: resolve => require.ensure([], () => resolve(require("../views/index/pages/aftermarket/index.vue"))),
        meta: {
            parent: "aftermarket"
        }
    }, 
    {
        path: "/aftermarketconfig",
        name: "售后配置",
        component: resolve => require.ensure([], () => resolve(require("../views/index/pages/aftermarketconfig/index.vue"))),
        meta: {
            parent: "aftermarket"
        }
    }, 
    {
        path: "/refund",
        name: "退款管理",
        component: resolve => require.ensure([], () => resolve(require("../views/index/pages/refund/index.vue"))),
        meta: {
            parent: "aftermarket"
        }
    },
    {
        path: "/message",
        name: "消息中心",
        component: resolve => require.ensure([], () => resolve(require("../views/index/pages/message/index.vue"))),
        meta: {}
    }
]

const router = new VueRouter({
    mode: 'history',
    base: BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (!window.sessionStorage.getItem("SHZS-LOGIN-TOKEN") && to.path != "/login") {
        location.pathname = BASE_URL + "login";
    } else {
        store.commit(SHOW_404_MUTATION, to.matched.length);
        store.commit(PARENT_MUTATION, to.meta.parent);
        next();
    }
})

export default router
