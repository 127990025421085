<template>
    <div class="header">
        <div class="toggle" @click="slide">
            <i :class="['el-icon-s-fold', { spread: spread }]"></i>
        </div>
        <div class="header-container">
            <div class="message group" @click="goMessage">
                <span>消息中心</span>
                <p class="tip" v-if="msgNum > 0">{{msgNum}}</p>
            </div>
            <p class="group">{{ user.groupName || "" }}</p>
            <el-popover
                placement="bottom-end"
                popper-class="user-popper"
                trigger="hover"
            >
                <p class="username" slot="reference">
                    {{ user.userName || "" }}
                </p>
                <div class="pull-down-panel">
                    <div class="item" @click="openPwd">修改密码</div>
                    <div class="item" @click="quit">退出登录</div>
                </div>
            </el-popover>
        </div>
        <el-dialog
            :visible.sync="visible"
            @close="close"
            custom-class="t-dialog"
            title="修改密码"
            width="600px"
        >
            <div class="dialog-body">
                <el-form
                    ref="form"
                    :model="form"
                    :rules="rules"
                    label-width="140px"
                    label-position="right"
                >
                    <el-form-item label="原密码" prop="originPwd">
                        <el-input
                            type="password"
                            v-model.trim="form.originPwd"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" prop="newPwd">
                        <el-input
                            type="password"
                            v-model.trim="form.newPwd"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="再次输入新密码" prop="reNewPwd">
                        <el-input
                            type="password"
                            v-model.trim="form.reNewPwd"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="dialog-footer" slot="footer">
                <el-button type="primary" @click="save">确定</el-button>
                <el-button @click="close">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { SPREAD_MUTATION } from "../../../store/mutationBus";
import {
    encryptedString,
    RSAKeyPair,
    setMaxDigits
} from "../../../utils/encrypt";
export default {
    data() {
        var validateNewPassword = (rule, value, callback)  => {
            if (!value || value == "") {
                callback(new Error("请输入新密码"));
            } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,16}$/.test(value)) {
                callback(new Error("密码需为6-16位大写字母、小写字母与数字组合, 可用特殊字符"));
            } else {
                callback();
            }
        }
        var validatePassword = (rule, value, callback) => {
            if (!value || value == "") {
                callback(new Error("请再次输入新密码"));
            } else if (value !== this.form.newPwd) {
                callback(new Error("两次输入密码不一致"));
            } else {
                callback();
            }
        };
        return {
            msgNum: 0,
            key: null,
            visible: false,
            form: {},
            rules: {
                originPwd: [
                    {
                        required: true,
                        message: "请输入原密码",
                        trigger: "blur"
                    }
                ],
                newPwd: [
                    {
                        required: true,
                        validator: validateNewPassword,
                        trigger: ["blur", "change"]
                    }
                ],
                reNewPwd: [
                    {
                        required: true,
                        validator: validatePassword,
                        trigger: ["blur", "change"]
                    }
                ]
            }
        };
    },
    computed: {
        ...mapState(["spread", "user"])
    },
    created() {
        this.getLoginInfo();
        this.getMessageNum();
    },
    methods: {
        getMessageNum() {
            this.$post("/shop/api/groupMsg/getNotReadNums", {}).then(data => {
                this.msgNum = data.number;
            })
        },
        goMessage() {
            this.$router.push("/message")
        },
        getLoginInfo() {
            this.$get("/open/getLoginInfo", { noToken: true })
                .then(data => {
                    const { exponents, modulus } = data;
                    setMaxDigits(260);
                    this.key = new RSAKeyPair(exponents, "", modulus);
                })
                .catch(() => {});
        },
        slide() {
            this.$store.commit(SPREAD_MUTATION);
        },
        openPwd() {
            this.visible = true;
        },
        save() {
            let password = encryptedString(this.key, this.form.originPwd),
                newPassword = encryptedString(this.key, this.form.newPwd);
            this.$refs.form.validate(valid => {
                if (!valid) return false;
                this.$post("/shop/api/user/updatePwd", {
                    password,
                    newPassword
                }).then(() => {
                    this.$notify.success("密码修改成功");
                    this.close();
                });
            });
        },
        close() {
            this.visible = false;
            this.form = {};
        },
        quit() {
            this.$post("/shop/api/user/loginOut").then(() => {
                location.pathname = process.env.VUE_APP_PUBLIC_PATH + "login";
            });
        }
    }
};
</script>

<style lang="scss">
.header {
    display: flex;
    justify-content: space-between;
    height: 100%;
    .toggle {
        cursor: pointer;
        padding: 0 15px;
        background-color: #222d32;
        box-shadow: 0 0 8px #525252;
        .el-icon-s-fold {
            font-size: 20px;
            color: #fff;
            transform: rotate(180deg);
            &.spread {
                transform: rotate(0);
            }
        }
    }
    .header-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        .message {
            position: relative;
            height: 20px;
            line-height: 20px;
            .tip {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(50%, -50%);
                height: 14px;
                line-height: 14px;
                min-width: 14px;
                padding: 0 3px;
                border-radius: 50%;
                font-size: 12px;
                color: #fff;
                background-color: #FF0000;
            }
        }
        .group {
            margin-right: 20px;
        }
        .username {
            padding-right: 20px;
        }
    }
}
.el-popover.user-popper {
    min-width: 100px;
    padding: 0;
    .pull-down-panel {
        cursor: pointer;
        .item {
            height: 36px;
            line-height: 36px;
            padding: 0 10px;
            text-align: center;
            &:hover {
                background: #acacac;
                color: #fff;
            }
        }
    }
}
.el-dialog.t-dialog {
    padding: 0;
    border-radius: 10px;
    .el-dialog__header {
        text-align: left;
        padding: 0 30px;
        border-bottom: 1px solid rgb(238, 238, 238);
        .el-dialog__title {
            font-weight: 600;
        }
    }
    .el-dialog__body {
        max-height: 60vh;
        overflow: auto;
        padding-bottom: 0;
    }
}
</style>