import * as MutationBus from './mutationBus'

export default {
    [MutationBus.SPREAD_MUTATION](state) {
        state.spread = !state.spread;
    },
    [MutationBus.USER_MUTATION](state, user) {
        state.user = user;
    },
    [MutationBus.PARENT_MUTATION](state, parent) {
        state.parent = parent;
    },
    [MutationBus.SHOW_404_MUTATION](state, flag) {
        state.show404 = !flag;
    }
}