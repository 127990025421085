<template>
    <div :class="['sidebar', { spread: spread }]">
        <div class="user-part">
            <div class="user-img">
                <img :src="user.groupImg || logo" alt="" />
            </div>
        </div>
        <div class="menu-container">
            <el-scrollbar style="height: 100%">
                <a
                    v-for="item in sidebar"
                    :class="['menu-item', { 'active': (item.page === parentTab || item.path === currPath) }]"
                    :page="item.page"
                    :parent="parentTab"
                    :key="item.page"
                    :to="item.path"
                    @click="routerGo(item)"
                >
                    <el-popover
                        v-if="item.children && item.children.length > 0"
                        placement="right"
                        trigger="hover"
                        popper-class="sub-popper"
                    >
                        <div class="submenu">
                            <a
                                v-for="it in item.children"
                                :class="['menu-item', { 'active': it.path === currPath }]"
                                :key="it.page"
                                :to="it.path"
                                @click="routerGo(it)"
                            >
                                <div class="menu-label">
                                    <i :class="['iconfont', it.icon]"></i>
                                    <span class="item-label">{{
                                        it.text
                                    }}</span>
                                </div>
                            </a>
                        </div>
                        <div class="menu-label" slot="reference">
                            <i :class="['iconfont', item.icon]"></i>
                            <span class="item-label">{{ item.text }}</span>
                        </div>
                    </el-popover>
                    <div v-else class="menu-label">
                        <i :class="['iconfont', item.icon]"></i>
                        <span class="item-label">{{ item.text }}</span>
                    </div>
                </a>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
const sidebar = [
    {
        icon: "icon-product",
        text: "商品管理",
        page: "product",
        path: "/product"
    },
    {
        icon: "icon-m-summary",
        text: "订单管理",
        page: "bill",
        path: "/bill",
        children: [
            {
                icon: "icon-m-summary",
                text: "订单管理",
                page: "bill",
                path: "/bill"
            },
            {
                icon: "icon-msg",
                text: "评价管理",
                page: "msg",
                path: "/msg"
            }
        ]
    },
    {
        icon: "icon-aftermarket",
        text: "售后退款",
        page: "aftermarket",
        path: "/aftermarketconfig",
        children: [
            {
                icon: "icon-af-setting",
                text: "售后配置",
                page: "aftermarketconfig",
                path: "/aftermarketconfig"
            },
            {
                icon: "icon-aftermarket",
                text: "售后管理",
                page: "aftermarket",
                path: "/aftermarket"
            },
            {
                icon: "icon-refund",
                text: "退款管理",
                page: "refund",
                path: "/refund"
            }
        ]
    }
];
import { mapState } from "vuex";
import logo from "../../../assets/logo.png";
export default {
    data() {
        return {
            logo: logo,
            sidebar: sidebar,
            parentTab: "",
            currPath: ""
        };
    },
    watch: {
        "$route"() {
            this.parentTab = (this.$route.meta && this.$route.meta.parent) ? this.$route.meta.parent : "";
            this.currPath = this.$route.path;
        }
    },
    computed: {
        ...mapState(["user", "spread", "parent"])
    },
    methods: {
        routerGo(item) {
            if ((item.children && item.children.length > 0) || item.path === this.currPath) return;
            this.$router.push(item.path)
        }
    }
};
</script>

<style lang="scss">
.sidebar {
    overflow: hidden;
    width: 100%;
    height: 100%;
    .user-part {
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        height: 80px;
        .user-img img {
            width: 50px;
            height: 50px;
            border: 1px #fff solid;
            border-radius: 50%;
        }
    }
    .menu-container {
        height: calc(100% - 80px);
        .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .menu-item {
            position: relative;
            display: flex;
            padding-left: 16px;
            width: 100%;
            height: 40px;
            line-height: 40px;
            color: #b8c7ce;
            font-size: 14px;
            align-content: center;
            text-decoration: none;
            border-left: 3px solid transparent;
            cursor: pointer;
            box-sizing: border-box;
            background-color: #222d32;
            &.active,
            &.router-link-exact-active {
                border-left-color: #2d8cf0;
                background: #fff;
                color: #2d8cf0;
            }
            &:hover {
                border-left-color: #2d8cf0;
            }
            > span {
                width: 100%;
            }
            .menu-label {
                width: 100%;
            }
            .iconfont {
                color: #b8c7ce;
                margin-right: 10px;
                font-size: 16px;
            }
            .item-label {
                display: inline-block;
                white-space: nowrap;
            }
        }
    }
}
.sub-popper.el-popover {
    min-width: 130px;
    padding: 0;
    box-shadow: 0 2px 32px 0 rgba(0, 0, 0, .4);
    background-color: #222d32;
    .menu-item {
        position: relative;
        display: flex;
        padding-left: 16px;
        width: 100%;
        height: 40px;
        line-height: 40px;
        color: #b8c7ce;
        font-size: 14px;
        align-content: center;
        text-decoration: none;
        border-left: 3px solid transparent;
        cursor: pointer;
        box-sizing: border-box;
        background-color: #222d32;
        &.active,
        &.router-link-exact-active {
            // border-left-color: #2d8cf0;
            background: #fff;
            color: #2d8cf0;
        }
        &:not(.router-link-exact-active):hover {
            border-left-color: #2d8cf0;
        }
        .iconfont {
            color: #b8c7ce;
            margin-right: 10px;
            font-size: 16px;
        }
        .item-label {
            display: inline-block;
            white-space: nowrap;
        }
    }
    &[x-placement^="right"] {
        margin-left: 0;
        .popper__arrow::after {
            border-right-color: #222d32;
        }
    }
}
</style>