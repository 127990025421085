<template>
    <div class="login-page">
        <div class="login-panel">
            <div class="img-panel">
                <img src="../../assets/image/login_panel.png" />
            </div>
            <div class="form-panel">
                <p class="login-title">商户管理系统</p>
                <el-form
                    ref="loginForm"
                    label-width="auto"
                    label-position="top"
                    :model="form"
                    :rules="rules"
                >
                    <el-form-item label="用户名" prop="account">
                        <el-input v-model.trim="form.account">
                            <i class="iconfont icon-username" slot="prefix"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="pwd">
                        <el-input v-model.trim="form.pwd" type="password">
                            <i class="iconfont icon-password" slot="prefix"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="验证码" prop="captcha">
                        <div class="captcha-container">
                            <el-input v-model.trim="form.captcha">
                                <i
                                    class="iconfont icon-captcha"
                                    slot="prefix"
                                ></i>
                                <img
                                    slot="suffix"
                                    id="captcha"
                                    width="124"
                                    height="38"
                                    :src="captchaImg"
                                    @click="refCaptcha()"
                                />
                            </el-input>
                            <!-- <img
                                id="captcha"
                                width="124"
                                height="40"
                                :src="captchaImg"
                                @click="refCaptcha()"
                            /> -->
                        </div>
                    </el-form-item>
                </el-form>
                <div class="control">
                    <el-button @click="reset">重置</el-button>
                    <el-button type="primary" @click="submit">登录</el-button>
                </div>
            </div>
        </div>
        <!-- <div class="record" v-if="false">
            <a href="https://beian.miit.gov.cn" target="_blank"
                >宁ICP备17001811号-2</a
            >
        </div> -->
    </div>
</template>

<script>
import { encryptedString, RSAKeyPair, setMaxDigits } from "../../utils/encrypt";

export default {
    name: "Login",
    data() {
        return {
            captchaImg: "",
            key: null,
            form: {},
            branList: [],
            rules: {
                account: [
                    {
                        required: true,
                        message: "*请输入用户名",
                        trigger: ["blur", "change"]
                    }
                ],
                pwd: [
                    {
                        required: true,
                        message: "*请输入密码",
                        trigger: ["blur", "change"]
                    }
                ],
                captcha: [
                    {
                        required: true,
                        message: "*请输入验证码",
                        trigger: ["blur", "change"]
                    }
                ]
            }
        };
    },
    created() {
        window.sessionStorage.removeItem("SHZS-LOGIN-TOKEN");
        window.sessionStorage.removeItem("SHZS-AUTH-CODE");
        this.$get("/open/getLoginInfo", { noToken: true })
            .then(data => {
                const { exponents, modulus } = data;
                window.sessionStorage.setItem("SHZS-AUTH-CODE", data.authCode);
                this.refCaptcha();
                setMaxDigits(260);
                this.key = new RSAKeyPair(exponents, "", modulus);
            })
            .catch(() => {});
    },
    methods: {
        submit() {
            this.$refs.loginForm.validate(valid => {
                if (!valid) return false;
                this.form.password = encryptedString(
                    this.key, this.form.pwd
                );
                delete this.form.pwd;
                // this.$loadingInstance.show();
                this.$post(
                    "/open/login",
                    Object.assign(this.form, {
                        noToken: true,
                        authCode:
                            window.sessionStorage.getItem("SHZS-AUTH-CODE")
                    })
                )
                    .then(data => {
                        this.$refs.loginForm.validateField("captcha");
                        window.sessionStorage.setItem("SHZS-LOGIN-TOKEN", data);
                        location.pathname = process.env.BASE_URL;
                    })
                    .catch(() => {
                        delete this.form.captcha;
                        this.refCaptcha();
                    })
                    .finally(() => {
                        // this.$loadingInstance.hide();
                    });
            });
        },
        reset() {
            this.$refs.loginForm.clearValidate();
            this.form = {};
        },
        refCaptcha() {
            this.$get(
                "/open/getCaptcha?authCode=" +
                    window.sessionStorage.getItem("SHZS-AUTH-CODE")
            )
                .then(data => {
                    this.captchaImg = data;
                })
                .catch(() => {});
        }
    }
};
</script>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
}
html,
body {
    height: 100%;
    overflow: hidden;
}
.login-page {
    width: 100%;
    height: 100%;
    position: relative;
    background-image: url("../../assets/image/login_bg.jpg");
    background-size: cover;
    .record {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        color: #acacac;
        font-size: 12px;
        a {
            color: inherit;
            text-decoration: none;
        }
    }
    .login-panel {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #ffffff;
        border-radius: 10px;
        display: flex;
        .img-panel img {
            height: 400px;
            width: auto;
            display: block;
        }
        .form-panel {
            padding: 20px;
        }
        .el-form {
            width: 300px;
            padding-bottom: 20px;
            .el-form-item {
                margin-bottom: 5px;
                .captcha-container {
                    display: flex;
                    #captcha {
                        cursor: pointer;
                        margin-top: 1px;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }
            }
            .iconfont {
                color: rgb(31, 138, 230);
                font-size: 18px;
                font-weight: bold;
                &.icon-username {
                    font-weight: normal;
                }
            }
            .el-input {
                z-index: 10;
                background-color: transparent;
                .el-input__inner {
                    background-color: transparent;
                }
            }
            .el-input__suffix {
                right: 2px;
            }
            .el-form-item__label {
                padding: 0;
                line-height: 30px;
            }
            .el-form-item__error {
                top: 50%;
                left: 30px;
                transform: translateY(-50%);
                padding-top: 0;
                z-index: 1;
            }
            .el-select {
                width: 100%;
            }
        }
        .login-title {
            font-size: 28px;
            margin-bottom: 20px;
            font-weight: 600;
        }
        .control {
            display: flex;
            .el-button {
                flex: 1;
            }
        }
    }
}
</style>