<template>
    <el-table-column
        :width="column.width"
        :label="column.label"
        :prop="column.prop"
        :sortable="column.sortable || false"
        :align="column.align || 'center'"
    >
        <template v-if="column.children">
            <query-column
                v-for="mc in column.children"
                :key="mc.label"
                :column="mc"
                :tableMap="tableMap"
            ></query-column>
        </template>
        <template slot-scope="scope">
            <template v-if="column.type == 'bill_intro'">
                <p style="text-align: left">{{ scope.row.intro }}</p>
                <p style="text-align: left; font-size: 12px; color: #ccc">
                    {{ scope.row.spec || "默认规格" }}
                </p>
            </template>
            <template v-else-if="column.type == 'sale'">
                <p>实际：{{ scope.row[column.prop].actual }}</p>
                <p>预置：{{ scope.row[column.prop].preview }}</p>
            </template>
            <template v-else-if="column.type == 'time'">
                <span>{{
                    scope.row[column.prop] == "1970-01-01 00:00:00"
                        ? "-"
                        : scope.row[column.prop]
                }}</span>
            </template>
            <template v-else-if="column.type == 'second'">
                <span>{{ parseSecond(scope.row[column.prop]) }}</span>
            </template>
            <template v-else-if="column.type == 'during'">
                <span>{{ exchangeSecond(scope.row[column.prop]) }}</span>
            </template>
            <template v-else-if="column.type == 'checkbox'">
                <el-checkbox
                    class="table-check"
                    :disabled="true"
                    v-model="scope.row[column.prop]"
                ></el-checkbox>
            </template>
            <template v-else-if="column.type == 'img'">
                <img
                    :src="scope.row[column.prop] || logo"
                    class="table-inner-image"
                />
            </template>
            <template v-else>
                <i
                    v-if="column.showIcon"
                    :class="['iconfont', scope.row[column.prop]]"
                ></i>
                <template v-else>
                    <i
                        v-if="column.rowIcon"
                        :class="['iconfont', scope.row.rowIcon]"
                    ></i>
                    <el-popover
                        v-if="tableMap && tableMap[column.prop]"
                        trigger="click"
                        popper-class="table-cell-popper"
                    >
                        <span>{{
                            tableMap[column.prop][scope.row[column.prop]]
                        }}</span>
                        <span slot="reference" class="cell-text">{{
                            tableMap[column.prop][scope.row[column.prop]]
                        }}</span>
                    </el-popover>
                    <template v-else>
                        <span v-if="column.percent" class="cell-text">{{
                            (scope.row[column.prop] * 100).toFixed(
                                column.precision || 2
                            ) + "%"
                        }}</span>
                        <span v-else-if="column.decimal" class="cell-text">{{
                            scope.row[column.prop].toFixed(
                                column.precision || 2
                            ) + "%"
                        }}</span>
                        <el-popover
                            v-else
                            trigger="click"
                            popper-class="table-cell-popper"
                        >
                            <div>{{ scope.row[column.prop] }}</div>
                            <div v-if="column.subProp">
                                {{ scope.row[column.subProp] }}
                            </div>
                            <div slot="reference" class="cell-text">
                                <p>{{ scope.row[column.prop] }}</p>
                                <p v-if="column.subProp">
                                    {{ scope.row[column.subProp] }}
                                </p>
                            </div>
                        </el-popover>
                    </template>
                </template>
            </template>
        </template>
    </el-table-column>
</template>

<script>
import logo from "../../../assets/logo.png";
export default {
    name: "QueryColumn",
    data() {
        return {
            logo: logo
        };
    },
    props: {
        column: { type: Object, default: null },
        tableMap: { type: Object, default: null }
    },
    methods: {
        exchangeSecond(sec, split) {
            let value = parseInt(sec);
            let H = Math.floor(value / 3600);
            let M = Math.floor((value % 3600) / 60);
            let S = (value % 3600) % 60;
            return (
                (H > 0 ? H + (split || "时") : "") +
                (M > 0 ? M + (split || "分") : "") +
                (S > 0 ? S + (split || "秒") : "")
            );
        },
        parseSecond(sec) {
            sec = Math.floor(sec / 1000);
            let H = Math.floor(sec / 3600);
            let M = Math.floor((sec % 3600) / 60);
            let S = Math.floor((sec % 3600) % 60);
            H < 10 && (H = "0" + H);
            M < 10 && (M = "0" + M);
            S < 10 && (S = "0" + S);
            if (H > 24) {
                H = 23;
                M = 59;
                S = 59;
            }
            return H + ":" + M + ":" + S;
        }
    }
};
</script>

<style lang="scss">
.cell-text {
    p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.table-check {
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #409eff;
        border-color: #409eff;
        cursor: default;
    }
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
        border-color: #fff;
    }
}
.table-cell-popper {
    max-width: 300px;
    min-width: unset;
}
.table-inner-image {
    width: 50px;
    height: 50px;
}
</style>
