<template>
    <div id="app">
        <el-container>
            <el-aside :width="spread ? '130px' : '0'">
                <Sidebar />
            </el-aside>
            <el-container>
                <el-header height="50px">
                    <Header />
                </el-header>
                <el-main>
                    <div class="content">
                        <div v-if="show404" class="page-404">
                            <i class="iconfont icon-404"></i>
                            <p class="text">
                                页面走丢了, 去<router-link to="/"
                                    >首页</router-link
                                >看看
                            </p>
                        </div>
                        <router-view v-else></router-view>
                    </div>
                </el-main>
                <!-- <div class="record" v-if="false">
                    <a href="https://beian.miit.gov.cn" target="_blank"
                        >宁ICP备17001811号-2</a
                    >
                </div> -->
            </el-container>
        </el-container>
    </div>
</template>

<script>
import Header from "./pieces/header.vue";
import Sidebar from "./pieces/sidebar.vue";
import { mapState } from "vuex";
import { USER_MUTATION } from "../../store/mutationBus";
export default {
    computed: {
        ...mapState(["spread", "show404"])
    },
    created() {
        this.getLoginUser();
    },
    methods: {
        /**
         * 获取登陆用户信息
         */
        getLoginUser() {
            this.$post("/shop/api/user/getUserInfo").then(data => {
                this.$store.commit(USER_MUTATION, data);
            });
        }
    },
    components: {
        Header,
        Sidebar
    }
};
</script>

<style lang="scss">
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html,
body,
#app,
.el-container {
    width: 100%;
    height: 100%;
}
#app {
    .record {
        font-size: 12px;
        color: #acacac;
        text-align: center;
        padding: 5px;
        a {
            color: inherit;
            text-decoration: none;
        }
    }
    .el-header {
        background-color: #fff;
        color: #333;
        text-align: center;
        height: 50px;
        line-height: 50px;
        padding: 0;
    }

    .el-aside {
        background-color: rgb(34, 45, 50);
        color: #fff;
        transition: width 0.3s ease;
        &.mini {
            width: 50px;
        }
    }

    .el-main {
        background-color: rgb(236, 240, 245);
        padding: 10px;
        height: 0;
        &::-webkit-scrollbar {
            width: 0;
        }
        .content {
            background: #fff;
            height: 100%;
            position: relative;
            > * {
                height: 100%;
            }
            .page-404 {
                position: absolute;
                height: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                font-size: 24px;
                .iconfont {
                    font-size: 180px;
                    margin-bottom: 30px;
                }
            }
        }
    }
}
</style>