import axios from "axios";
import Vue from "vue";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL || "";
axios.defaults.withCredentials = true;

let _vue = new Vue();

axios.interceptors.request.use(config => {
    let token = window.sessionStorage.getItem("SHZS-LOGIN-TOKEN");
    if (!config.noToken && !!token) {
        config.headers.Authorization = token;
    }
    return config;
}, error => Promise.reject(error));

axios.interceptors.response.use(response => {
    if (!response || !response.data || response.data.userStatus == 300) {
        location.pathname = process.env.VUE_APP_PUBLIC_PATH + "login"
    }
    if (!response.data.success || response.status !== 200) {
        _vue.$notify.error({
            title: response.data.message || "请求失败1"
        })
        return Promise.reject();
    }
    return response.data.model;
}, error => {
    _vue.$notify.error({
        title: "请求失败2"
    })
    return Promise.reject(error);
})
